'use client'

import { FunctionComponent, useContext, useEffect } from 'react'
import { CartContext } from '../../context/CartContext'
import { UserContext } from '../../context/UserContext'
import { gtmPushCartEvent, waitForPageView } from '../../utils/gtmHelpers'

const CartGtmEvent: FunctionComponent<{ event: string }> = ({ event }) => {
  const { cart } = useContext(CartContext)
  const { hydrated } = useContext(UserContext)

  useEffect(() => {
    if (!hydrated) {
      return
    }
    const aborted = { value: false };

    (async () => {
      await waitForPageView('Tunnel de vente', aborted)
      if (aborted.value) {
        return
      }
      gtmPushCartEvent({ event, cart })
    })()

    return () => {
      aborted.value = true
    }
  }, [hydrated])

  return null
}

export default CartGtmEvent
